body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  display: flex;
  flex-wrap: wrap;
  width: 96%;
  margin-left: 0;
  padding: 12px;
  height: 20px;
  padding-right: 2%;
  padding-left: 2%;
}

.item {
  flex: 1 1 0px;
  color: black;
  margin: 10px;
  align-items: center;
  justify-content: center;
  flex-basis: 10%;
  margin: 0;
  padding: 0;
  height: 30px;
}


.header-score {
  text-align: left;
  margin-left: 2%;
}

.guesses {
  margin-bottom: 10px;
}
.color-item {
  flex: 1 1 0px;
  display: flex;
  color: black;
  margin: auto;
  align-items: center;
  justify-content: center;
  flex-basis: 20%;
  text-align: center;
  padding: 0;
  height: 40px;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  background-color: rgb(214, 214, 214);
  font-size: .75em;
  align-self: center;
  flex-direction: column;
}

.color-bar {
  display:flex;
  width: 96%;
  height: 50px;
  margin-left: 2%;
  margin-right: 2%;
}

.color-bar-score {
  display: flex;
  width: 96%;
  height: 25px;
  margin-left: 2%;
  margin-right: 2%;
}

.color-bar-item {
  height: 100%;
  width: 100%;
  flex: 0 0 1;
}

.clickable {
  cursor: pointer;
}

.fixed {
  position: -webkit-sticky;
  position: fixed;
  top: 0;
  background-color: rgb(255, 255, 255);
  width: 100%;
  padding-bottom: 5px;
  border-bottom: 1px solid black;
}


.non-content {
  margin-top: 190px;
}


.picker-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  border-top: 1px solid black;
  border-left: 1px solid black;
}

.low-guess {
  display: flex;
  margin-right: 5px;
  height: 5;
  width: 80px;
  text-align: center;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
} 

.guess-placeholder {
  color: white; 
}

.high-guess {
  display: flex;
  margin-left: 5px;
  height: 5;
  width: 80px;
  text-align: center;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
} 

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

html, body {margin: 0; height: 100%; overflow-x: hidden;}

h1 { margin-bottom: 5px; }
